/* Wrapper for centering */
.file-upload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to right, #f8f9fa, #e3f2fd);
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Background box with hover effect */
.hover-box {
  background: white;
  border-radius: 20px;
  padding: 40px 30px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-box:hover {
  transform: scale(1.02);
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
}

/* Header styling */
.header {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 15px;
}

/* Subheader styling */
.subheader {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

/* Drop zone styling */
.drop-zone {
  width: 100%;
  max-width: 400px;
  height: 150px;
  border: 2px dashed #007bff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #333;
  cursor: pointer;
  margin: 20px auto;
  transition: background-color 0.3s, border-color 0.3s;
}

.drop-zone.dragging {
  background-color: #e3f2fd;
  border-color: #0056b3;
}

.drop-zone p {
  font-size: 16px;
  margin: 0;
}

/* Button styling */
.upload-button,
.download-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background-color: #218838;
}

.download-button {
  background-color: #007bff;
  text-decoration: none;
}

.download-button:hover {
  background-color: #0056b3;
}

/* reCAPTCHA wrapper for centering */
.recaptcha-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0; /* Adjust spacing as needed */
}