.footer {
    background-color: #282c34;
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-nav {
    margin-top: 10px;
  }
  
  .footer-nav a {
    color: #61dafb;
    margin: 0 10px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }  